import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import {
    ChartPieIcon,
    ChevronDoubleRightIcon,
    CloudUploadIcon,
    DatabaseIcon,
    FlagIcon,
    HashtagIcon,
    MailIcon,
    MenuAlt2Icon,
    MenuIcon,
    QuestionMarkCircleIcon,
    SupportIcon,
    XIcon
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment, useRef } from "react";


type Props = {
    isLoading: boolean;
};


const solutions = [
    {
        name: "Databases",
        description: "Blazing fast database service for storing player data in your experiences.",
        href: "/solutions/database",
        icon: DatabaseIcon
    },
    {
        name: "Feature Flags",
        description: "Roll out updates programmatically using feature flags.",
        href: "/solutions/feature-flags",
        icon: FlagIcon
    },
    {
        name: "Application Centres",
        description: "Set up modern application centers with the click of a button. Free forever.",
        href: "/solutions/application-centres",
        icon: MailIcon
    },
    {
        name: "Logs",
        description: "Logging infrastructure to make debugging less of a painful task.",
        href: "/solutions/logs",
        icon: MenuAlt2Icon
    },
    {
        name: "Analytics",
        description: "Robase Live (analytics) offers powerful insights to make informative decisions, based on data.",
        href: "/solutions/analytics",
        icon: ChartPieIcon
    },
    {
        name: "Chat Filter",
        description: "Robase Chat Filter ensures your game chat is clean from all inappropriate messages and scams.",
        href: "/solutions/chat-filter",
        icon: HashtagIcon
    },
    {
        name: "Proxy",
        description: "Robase Proxy allows you to send API requests to Roblox's Web APIs, like Rprxy but managed.",
        href: "/solutions/proxy",
        icon: ChevronDoubleRightIcon
    },
    {
        name: "RoSync",
        description: "Robase RoSync allows you to manage your Roblox community on Discord by linking Roblox accounts and more.",
        href: "/solutions/rosync",
        icon: CloudUploadIcon
    },
    {
        name: "CRM (Customer Relationship Mgmt.)",
        description: "Handle support tickets inquiries with ease using Robase CRM.",
        href: "/solutions/crm",
        icon: QuestionMarkCircleIcon
    }
];
const callsToAction = [
    {
        name: "Contact Us",
        href: "/about/contact-us",
        icon: SupportIcon
    }
];

function classNames (...classes: any) {
    return classes.filter(Boolean).join(" ");
}


type NavBarNavigationType = {
    name: string;
    href: string;
    items?: {
        name: string;
        href: string;
        icon: any;
    }[];
};

const navBarNavigation: NavBarNavigationType[] = [
    {
        name: "Product",
        href: "#"
    },
    {
        name: "Features",
        href: "#"
    },
    {
        name: "Marketplace",
        href: "#"
    },
    {
        name: "Company",
        href: "#"
    },
    {
        name: "Joe Mama",
        href: "#"
    }
];


export default function DashboardSectionNavBar (props: Props) {
    const closePopoverRef = useRef(null);
    const closePopoverSmallRef = useRef(null);
    const closePopoverMediumRef = useRef(null);
    
    const handlePostButtonClick = () => {
        if (closePopoverRef && closePopoverRef.current) {
            // @ts-ignore
            closePopoverRef.current!.click();
        }
        
        if (closePopoverSmallRef && closePopoverSmallRef.current) {
            // @ts-ignore
            closePopoverSmallRef.current!.click();
        }
        
        if (closePopoverMediumRef && closePopoverMediumRef.current) {
            // @ts-ignore
            closePopoverMediumRef.current!.click();
        }
    };
    
    return (
        <>
            <Popover className="bg-white fixed w-full shadow-md top-0 z-50">
                { ({ open }) => (
                    <>
                        <div className="absolute inset-0 shadow z-30 pointer-events-none" aria-hidden="true"/>
                        <div className="relative z-20">
                            <div
                                className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
                                <div>
                                    <Link to="/" className="flex">
                                        <span className="sr-only">Robase</span>
                                        <img
                                            className="h-8 w-auto sm:h-10"
                                            src="/assets/img/logos/robase_logo.svg"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="-mr-2 -my-2 md:hidden">
                                    <Popover.Button
                                        ref={ closePopoverMediumRef }
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Open menu</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                                    </Popover.Button>
                                </div>
                                <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                                    <Popover.Group as="nav" className="flex space-x-10">
                                        <Popover>
                                            { ({ open }) => (
                                                <>
                                                    <Popover.Button
                                                        ref={ closePopoverRef }
                                                        className={ classNames(
                                                            open ? "text-gray-900" : "text-gray-500",
                                                            "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        ) }
                                                    >
                                                        <span>Products</span>
                                                        <ChevronDownIcon
                                                            className={ classNames(
                                                                open ? "text-gray-600" : "text-gray-400",
                                                                "ml-2 h-5 w-5 group-hover:text-gray-500"
                                                            ) }
                                                            aria-hidden="true"
                                                        />
                                                    </Popover.Button>
                                                    
                                                    <Transition
                                                        show={ open }
                                                        as={ Fragment }
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="opacity-0 -translate-y-1"
                                                        enterTo="opacity-100 translate-y-0"
                                                        leave="transition ease-in duration-150"
                                                        leaveFrom="opacity-100 translate-y-0"
                                                        leaveTo="opacity-0 -translate-y-1"
                                                    >
                                                        <Popover.Panel
                                                            static
                                                            className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white"
                                                        >
                                                            <div
                                                                className="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                                                                { solutions.map((item) => (
                                                                    <Link
                                                                        key={ item.name }
                                                                        to={ item.href }
                                                                        onClick={ handlePostButtonClick }
                                                                        className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-200"
                                                                    >
                                                                        <div className="flex md:h-full lg:flex-col">
                                                                            <div className="flex-shrink-0">
                                      <span
                                          className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                        <item.icon className="h-6 w-6" aria-hidden="true"/>
                                      </span>
                                                                            </div>
                                                                            <div
                                                                                className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                                                                <div>
                                                                                    <p className="text-base font-medium text-gray-900">{ item.name }</p>
                                                                                    <p className="mt-1 text-sm text-gray-500">{ item.description }</p>
                                                                                </div>
                                                                                <p className="mt-2 text-sm font-medium text-indigo-600 lg:mt-4">
                                                                                    Learn more <span
                                                                                    aria-hidden="true">&rarr;</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                )) }
                                                            </div>
                                                            <div className="bg-gray-50">
                                                                <div
                                                                    className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                                                                    { callsToAction.map((item) => (
                                                                        <div key={ item.name } className="flow-root">
                                                                            <Link
                                                                                to={ item.href }
                                                                                onClick={ handlePostButtonClick }
                                                                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-400"
                                                                            >
                                                                                <item.icon
                                                                                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                                                                                    aria-hidden="true"/>
                                                                                <span
                                                                                    className="ml-3">{ item.name }</span>
                                                                            </Link>
                                                                        </div>
                                                                    )) }
                                                                </div>
                                                            </div>
                                                        </Popover.Panel>
                                                    </Transition>
                                                </>
                                            ) }
                                        </Popover>
                                        <Link to="/solutions"
                                              className="text-base font-medium text-gray-500 hover:text-gray-900">
                                            Our Solutions
                                        </Link>
                                        <Link to="/pricing"
                                              className="text-base font-medium text-gray-500 hover:text-gray-900">
                                            Pricing
                                        </Link>
                                        <Link to="/docs"
                                              className="text-base font-medium text-gray-500 hover:text-gray-900">
                                            Docs
                                        </Link>
                                    </Popover.Group>
                                    <div className="flex items-center md:ml-12">
                                        <Link
                                            to="/dashboard/auth/login"
                                            className="text-base font-medium text-gray-500 hover:text-gray-900"
                                        >
                                            Sign in
                                        </Link>
                                        <Link
                                            to="/dashboard/auth/register"
                                            className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                        >
                                            Sign up
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={ `gradient-loader w-full h-0.5 ${ props.isLoading ? "" : "invisible" }` }
                        />
                        
                        <Transition
                            show={ open }
                            as={ Fragment }
                            enter="duration-200 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                static
                                className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                            >
                                <div
                                    className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                    <div className="pt-5 pb-6 px-5 sm:pb-8">
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <img
                                                    className="h-8 w-auto"
                                                    src="/assets/img/logos/robase_logo.svg"
                                                    alt="Workflow"
                                                />
                                            </div>
                                            <div className="-mr-2">
                                                <Popover.Button
                                                    ref={ closePopoverSmallRef }
                                                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                    <span className="sr-only">Close menu</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                </Popover.Button>
                                            </div>
                                        </div>
                                        <div className="mt-6 sm:mt-8">
                                            <nav>
                                                <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                                                    { solutions.map((item) => (
                                                        <Link
                                                            key={ item.name }
                                                            to={ item.href }
                                                            onClick={ handlePostButtonClick }
                                                            onTouchEnd={ handlePostButtonClick }
                                                            className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-400"
                                                        >
                                                            <div
                                                                className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                                                <item.icon className="h-6 w-6" aria-hidden="true"/>
                                                            </div>
                                                            <div
                                                                className="ml-4 text-base font-medium text-gray-900">{ item.name }</div>
                                                        </Link>
                                                    )) }
                                                </div>
                                                <div className="mt-8 text-base">
                                                    <a href="#"
                                                       className="font-medium text-indigo-600 hover:text-indigo-500">
                                                        { " " }
                                                        View all products <span aria-hidden="true">&rarr;</span>
                                                    </a>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="py-6 px-5">
                                        <div className="grid grid-cols-2 gap-4">
                                            <Link to="/solutions"
                                                  className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                                Our Solutions
                                            </Link>
                                            <Link to="/docs"
                                                  className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                                Pricing
                                            </Link>
                                            <Link to="/docs"
                                                  className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                                Docs
                                            </Link>
                                            
                                            <Link to="/about/contact-us"
                                                  className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                                Contact Sales
                                            </Link>
                                        </div>
                                        <div className="mt-6">
                                            <Link
                                                to="/dashboard/auth/register"
                                                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-lg text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                Sign up
                                            </Link>
                                            <p className="mt-6 text-center text-base font-medium text-gray-500">
                                                Existing customer?{ " " }
                                                <Link to="/dashboard/auth/login"
                                                      className="text-indigo-600 hover:text-indigo-500">
                                                    Sign in
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                ) }
            </Popover>
        </>
    );
};
