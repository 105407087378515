import { renderRoutes } from "react-router-config";
import { cloudflarePagesRoutes } from "./routes";


export default function CloudflarePagesSection () {
    return (
        <>
            {
                renderRoutes(cloudflarePagesRoutes)
            }
        </>
    );
};
