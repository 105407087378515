import { RouteConfig } from "react-router-config";
import LandingSection from "./LandingSection";
import DashboardSection from "./Dashboard";
import CloudflarePagesSection from "./cloudflare-pages/CloudflarePagesSection";


const appRoutes: RouteConfig[] = [
    {
        path: "/cloudflare-pages/",
        component: CloudflarePagesSection
    },
    {
        path: "/dashboard",
        component: DashboardSection
    },
    {
        path: "/",
        component: LandingSection
    }
];

export default appRoutes;
