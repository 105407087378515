import React, { Suspense, useState } from "react";
import { renderRoutes } from "react-router-config";
import { dashboardSectionRoutes } from "./routes";
import DashboardPageLoader from "./components/PageLoader";
import DashboardSectionNavBar from "./components/DashboardSectionNavBar";


export default function DashboardSection () {
    const [ isLoading, setIsLoading ] = useState(false);
    
    return (
        <>
            <DashboardSectionNavBar
                isLoading={ isLoading }
            />
            <Suspense fallback={ <DashboardPageLoader setIsLoading={ setIsLoading }/> }>
                {
                    renderRoutes(dashboardSectionRoutes)
                }
            </Suspense>
        </>
    );
};
