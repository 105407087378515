import { useEffect } from "react";


type Props = {
    setIsLoading: (isLoading: boolean) => void;
}

export default function LandingSectionLoader (props: Props) {
    useEffect(() => {
        props.setIsLoading(true);
        
        return () => {
            setTimeout(() => {
                props.setIsLoading(false);
            }, 1000);
        };
    }, []);
    
    useEffect(() => {
        console.log("use effect used!");
    }, []);
    
    return (
        <>
            hi
        </>
    );
};
