import { RouteConfig } from "react-router-config";
import { lazy } from "react";


export const landingSectionRoutes: RouteConfig[] = [
    {
        path: "/",
        exact: true,
        component: lazy(() => import("./main/LandingSectionMainView"))
    },
    {
        path: "/pricing",
        exact: true,
        component: lazy(() => import("./sub/pricing/PricingPage"))
    },
    {
        path: "/solutions",
        exact: false,
        component: lazy(() => import("./sub/solutions/SolutionsSection"))
    }
];
