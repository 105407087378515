import React, { Suspense, useEffect, useState } from "react";
import LandingSectionFooter from "./components/LandingSectionFooter";
import LandingSectionNavBar from "./components/LandingSectionNavBar";
import { renderRoutes } from "react-router-config";
import { landingSectionRoutes } from "./routes";
import generateHelmetData from "../../App/util/generateHelmetData";
import LandingSectionLoader from "./components/LandingSectionLoader";


export default function LandingSection () {
    const [ isLoading, setIsLoading ] = useState(false);
    
    
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    
    return (
        <>
            {
                /*
                    Generating meta tag information for SEO optimizations
                 */
                generateHelmetData({})
            }
                <LandingSectionNavBar
                    isLoading={ isLoading }
                />
                <div
                    style={ { height: "72px" } }
                />
    
                <Suspense fallback={ <LandingSectionLoader setIsLoading={ setIsLoading }/> }>
                    {
                        renderRoutes(landingSectionRoutes)
                    }
                </Suspense>
    
                <LandingSectionFooter/>
        </>
    );
};
