import { Helmet } from "react-helmet";
import websiteConfig from "./websiteConfig";


type InputHelmetData = {
    title?: string;
    description?: string;
    url?: string;
    image?: string;
}

export default function generateHelmetData (options: InputHelmetData) {
    return (
        <>
            <Helmet>
                <title>
                    { options.title || websiteConfig.websiteTitle }
                </title>
                <meta
                    name="og:title"
                    content={ options.title || websiteConfig.websiteTitle }
                />
                <meta
                    name="description"
                    content={ options.description || websiteConfig.websiteDescription }
                />
                <meta
                    name="og:description"
                    content={ options.description || websiteConfig.websiteDescription }
                />
                <meta
                    name="og:url"
                    content={ options.url || websiteConfig.websiteUrl }
                />
                <meta
                    name="og:image"
                    content={ options.image || websiteConfig.websiteImage }
                />
            </Helmet>
        </>
    );
};
