import { useLayoutEffect } from "react";


type PageLoaderProps = {
    setIsLoading: (isLoading: boolean) => void;
}

export default function DashboardPageLoader (props: PageLoaderProps) {
    useLayoutEffect(() => {
        props.setIsLoading(true);
        
        return () => {
            setTimeout(() => {
                props.setIsLoading(false);
            }, 1000);
        };
    });
    
    
    return (
        <>
            LOADING DASHBOARD
        </>
    );
};
