import { RouteConfig } from "react-router-config";
import { lazy } from "react";


export const cloudflarePagesRoutes: RouteConfig[] = [
    {
        path: "/cloudflare-pages/errors/page-429",
        exact: true,
        component: lazy(() => import("./errors/429-page/Cloudflare429Page"))
    },
    {
        path: "/cloudflare-pages/errors/waf-block",
        exact: true,
        component: lazy(() => import("./errors/waf-block/WAFBlock"))
    },
    {
        path: "/cloudflare-pages/errors/access-block",
        exact: true,
        component: lazy(() => import("./errors/access-block/AccessBlock"))
    },
    {
        path: "/cloudflare-pages/errors/unavailable",
        exact: true,
        component: lazy(() => import("./errors/unavailable/Unavailable"))
    },
    {
        path: "/cloudflare-pages/errors/basic-challenge",
        exact: true,
        component: lazy(() => import("./errors/basic-challenge/BasicChallenge"))
    },
    {
        path: "/cloudflare-pages/errors/waf-challenge",
        exact: true,
        component: lazy(() => import("./errors/waf-challenge/WAFChallenge"))
    },
    {
        path: "/cloudflare-pages/errors/country-challenge",
        exact: true,
        component: lazy(() => import("./errors/country-challenge/CountryChallenge"))
    },
    {
        path: "/cloudflare-pages/errors/under-attack",
        exact: true,
        component: lazy(() => import("./errors/under-attack/UnderAttack"))
    }
];
