import { RouteConfig } from "react-router-config";
import { Redirect } from "react-router-dom";
import { lazy } from "react";


export const dashboardSectionRoutes: RouteConfig[] = [
    {
        path: "/dashboard/auth/login",
        exact: true,
        component: lazy(() => import("./sub/auth/login"))
    },
    {
        path: "/",
        exact: false,
        component: () => <Redirect to="/auth/login"/>
    }
];
